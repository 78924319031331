export default {
    toolbar: {
        items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
            'insertTable',
            'fileUploadInline',
        ],
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    heading: {
        options: [
            {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph',
            },
            {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1',
            },
            {
                model: 'headingOneBlue',
                view: {
                    name: 'h1',
                    classes: 'headingBlue',
                },
                title: 'Heading 1 (Blue)',
                converterPriority: 'high',
            },
            {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2',
            },
            {
                model: 'headingTwoBlue',
                view: {
                    name: 'h2',
                    classes: 'headingBlue',
                },
                title: 'Heading 2 (Blue)',
                converterPriority: 'high',
            },
            {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'ck-heading_heading3',
            },
            {
                model: 'headingThreeBlue',
                view: {
                    name: 'h3',
                    classes: 'headingBlue',
                },
                title: 'Heading 3 (Blue)',
                converterPriority: 'high',
            },
            {
                model: 'intro',
                view: {
                    name: 'intro',
                    classes: 'introPurple',
                },
                title: 'Intro (Purple)',
                converterPriority: 'high',
            },
        ],
    },
};
