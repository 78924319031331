/**
 * Logic for the CPE List
 * Author: Imarc 2019
 */

// Dependencies
import moment from 'moment';
import IMask from 'imask';
import SlimSelect from 'slim-select';
import flatpickr from 'flatpickr';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import MdEditor from '@toast-ui/editor';

export default function initInputs(parentElement) {
    /**
     * Date mask
     * Uses IMask and moment
     * https://imask.js.org/guide.html#date
     * https://momentjs.com/docs/
     */
    const dateFieldFormat = 'MM/DD/YY';
    const dateFieldInputs = [...parentElement.querySelectorAll('.js-dateMask')];

    if (dateFieldInputs.length > 0) {
        dateFieldInputs.forEach(function dateMaskConstructor(dateField) {
            IMask(dateField, {
                mask: dateFieldFormat,
                pattern: dateFieldFormat,
                lazy: true,
                min: new Date(2000, 0, 1),
                max: new Date(2099, 0, 1),
                format: date => {
                    return moment(date).format(dateFieldFormat);
                },
                parse: str => {
                    return moment(str, dateFieldFormat);
                },
                blocks: {
                    YY: {
                        mask: '00',
                        from: '00',
                        to: 99,
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                    },
                    DD: {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 31,
                    },
                },
                overwrite: true,
            });
        });
    }

    /**
     * Phone mask
     * Uses IMask
     * https://imask.js.org/guide.html
     * https://momentjs.com/docs/
     */
    const phoneFieldFormat = '000-000-0000';
    const phoneFieldInputs = [...parentElement.querySelectorAll('.js-phoneMask')];

    if (phoneFieldInputs.length > 0) {
        phoneFieldInputs.forEach(function applyPhoneMask(phoneField) {
            IMask(phoneField, {
                mask: phoneFieldFormat,
                pattern: phoneFieldFormat,
                overwrite: true,
                lazy: true,
            });
        });
    }

    /**
     * Zip
     * Uses IMask
     * https://imask.js.org/guide.html
     * https://momentjs.com/docs/
     */
    const zipFieldFormat = '00000';
    const zipFieldInputs = [...parentElement.querySelectorAll('.js-zipMask')];

    if (zipFieldInputs.length > 0) {
        zipFieldInputs.forEach(function applyZipMask(zipField) {
            IMask(zipField, {
                mask: zipFieldFormat,
                pattern: zipFieldFormat,
                overwrite: true,
                lazy: true,
            });
        });
    }

    /**
     * Applies Slim Select
     * to selects that have been flagged
     * with the "js-select".
     * https://www.npmjs.com/package/slim-select
     */
    const selectFieldInputs = [...parentElement.querySelectorAll('.js-select')];

    if (selectFieldInputs.length > 0) {
        window.selectrInstances = {};

        selectFieldInputs.forEach(function applySelectPure(selectField) {
            const selectElement = selectField.querySelector('select');

            const select = new SlimSelect({
                select: selectElement,
                closeOnSelect: !selectElement.multiple,
                allowDeselect: true,
            });

            let filteredItem = null;

            const searchInput = selectField.querySelector('input[type="search"]');

            searchInput.addEventListener('input', () => {
                filteredItem = select.data.filtered ? select.data.filtered[0] : null;
            });

            searchInput.addEventListener('keydown', ({ key }) => {
                if (key === 'Tab' && filteredItem && !selectElement.multiple) {
                    select.set(filteredItem.value);
                }

                if (key === 'Enter' && filteredItem) {
                    select.set(filteredItem.value);
                }
            });

            window.selectrInstances[selectElement.name] = select;
            selectElement.slimSelect = select;
        });
    }

    /**
     * Applies Calendar Picker
     * https://flatpickr.js.org/getting-started/
     */
    const dateInputFields = [...parentElement.querySelectorAll('.js-dateRange')];

    if (dateInputFields.length > 0) {
        dateInputFields.forEach(function applyCalendarUi(dateInputField) {
            const afterField = dateInputField.querySelector('.js-dateAfter');
            const beforeField = dateInputField.querySelector('.js-dateBefore');
            const uiField = dateInputField.querySelector('.js-dateUi');

            dateInputField.addEventListener('input', event => {
                if (!(afterField.value && beforeField.value)) {
                    event.stopPropagation();
                }
            });

            dateInputField.addEventListener('change', event => {
                if (!(afterField.value && beforeField.value)) {
                    event.stopPropagation();
                }
            });

            if (dateInputField.querySelector('.js-dateGroup')) {
                afterField.calendar = flatpickr(afterField, {
                    dateFormat: 'm/d/Y',
                    defaultDate: afterField.value || null,
                    onClose: selectedDates => {
                        const dateConverted = moment(selectedDates[0]).format('M/DD/YYYY');
                        if (dateConverted.length === 0) {
                            afterField.value = null;
                        } else {
                            afterField.value = dateConverted;
                        }
                    },
                    onChange: selectedDates => {
                        if (selectedDates[0].length === 0) {
                            afterField.value = null;
                        }
                    },
                });
                beforeField.calendar = flatpickr(beforeField, {
                    dateFormat: 'm/d/Y',
                    defaultDate: beforeField.value || null,
                    onClose: selectedDates => {
                        const dateConverted = moment(selectedDates[0]).format('M/DD/YYYY');
                        if (dateConverted.length === 0) {
                            beforeField.value = null;
                        } else {
                            beforeField.value = dateConverted;
                        }
                    },
                    onChange: selectedDates => {
                        if (selectedDates[0].length === 0) {
                            beforeField.value = null;
                        }
                    },
                });
            } else {
                const calendar = flatpickr(uiField, {
                    mode: 'range',
                    dateFormat: 'm/d/Y',
                    defaultDate: [
                        afterField.value || null,
                        beforeField.value || null,
                    ],
                    onClose: selectedDates => {
                        const datesConverted = selectedDates.map(date =>
                            moment(date).format('M/DD/YYYY')
                        );

                        if (datesConverted.length > 1) {
                            // eslint-disable-next-line prefer-destructuring
                            afterField.value = datesConverted[0];
                            // eslint-disable-next-line prefer-destructuring
                            beforeField.value = datesConverted[1];
                        } else if (datesConverted.length === 1) {
                            uiField.value = datesConverted[0];
                            afterField.value = datesConverted[0];
                            beforeField.value = datesConverted[0];
                        } else {
                            afterField.value = null;
                            beforeField.value = null;
                        }
                    },
                    onChange: selectedDates => {
                        if (selectedDates.length === 0) {
                            afterField.value = null;
                            beforeField.value = null;
                        }
                    },
                });


                afterField.flatpickr = calendar;
                beforeField.flatpickr = calendar;
                uiField.flatpickr = calendar;
            }


        });
    }

    const timeRangeInputs = [...parentElement.querySelectorAll('.js-timeRange')];

    if (timeRangeInputs.length > 0) {
        timeRangeInputs.forEach(function applyTimeRange(timeRangeInput) {
            const rangeSliderUi = timeRangeInput.querySelector('.js-rangeUi');
            const inputMin = timeRangeInput.querySelector('.js-min');
            const inputMax = timeRangeInput.querySelector('.js-max');

            const convertHourIntegerFromUTC = (utcHour) => {
                const currentDate = new Date();
                const currentMonth = currentDate.toLocaleString('default', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                const utcDate = new Date(`${currentMonth} ${utcHour}:00:00 UTC`);
                let hours = utcDate.getHours();

                return hours;
            }

            const convertHourIntegerToUTC = (hour) => {
                const currentDate = new Date();
                currentDate.setHours(hour)
                const hours = currentDate.getUTCHours();
                return hours;
            }

            const initialInputMinValue = inputMin.value;
            const initialInputMaxValue = inputMax.value;

            inputMin.initialValue = initialInputMinValue;
            inputMax.initialValue = initialInputMaxValue;

            const initialSix = new Date();
            const initialNine = new Date();

            if (initialInputMinValue !== '6') {
                initialSix.setHours(convertHourIntegerFromUTC(parseInt(initialInputMinValue)));
            } else {
                initialSix.setHours(6);
            }

            if (initialInputMaxValue !== '21') {
                initialNine.setHours(convertHourIntegerFromUTC(parseInt(initialInputMaxValue)));
            } else {
                initialNine.setHours(21);
            }

            const initialSixUTCHours = initialSix.getUTCHours();
            const initialNineUTCHours = initialNine.getUTCHours();

            inputMin.value = initialSixUTCHours;
            inputMax.value = initialNineUTCHours;

            const numberFormat = wNumb({
                decimals: 0,
                edit: value => {
                    let hour = Number(value)
                    const ampm = hour >= 12 ? 'pm' : 'am'

                    if (hour > 12) {
                        hour = hour - 12
                    }

                    return `${hour} ${ampm}`
                }
            });

            const presets  = timeRangeInput.querySelectorAll('.js-preset');
            const uiSlider = noUiSlider.create(rangeSliderUi, {
                start: [initialSix.getHours(), initialNine.getHours()],
                connect: true,
                step: 1,
                tooltips: [numberFormat, numberFormat],
                range: {
                    min: parseInt(rangeSliderUi.dataset.min || '6'),
                    max: parseInt(rangeSliderUi.dataset.max || '21'),
                },
            });

            inputMin.noUiSlider = uiSlider;
            inputMax.noUiSlider = uiSlider;

            if (presets.length) {
                for (const preset of presets) {
                    preset.addEventListener('change', e => {
                        const input = e.target;
                        if (input.checked) {
                            uiSlider.set([parseInt(input.dataset.min), parseInt(input.dataset.max)]);
                            inputMin.value = parseInt(input.dataset.min);
                            inputMax.value = parseInt(input.dataset.max);
                        }
                    });
                }
            }


            uiSlider.on('change', values => {
                const formattedValues = values.map(value => wNumb({ decimals: 0 }).to(value * 1));

                // eslint-disable-next-line prefer-destructuring
                inputMin.value = convertHourIntegerToUTC(new Number(formattedValues[0]));
                // eslint-disable-next-line prefer-destructuring
                inputMax.value = convertHourIntegerToUTC(new Number(formattedValues[1]));

                const changeEvent = new Event('change', { bubbles: true });
                timeRangeInput.dispatchEvent(changeEvent);
            });
        });
    }

    /**
     * Applies Range Slider
     * https://refreshless.com/nouislider/
     */
    const rangeSliders = [...parentElement.querySelectorAll('.js-range')];

    if (rangeSliders.length > 0) {
        rangeSliders.forEach(function applyRangeSlider(rangeSlider) {
            const rangeSliderUi = rangeSlider.querySelector('.js-rangeUi');
            const inputMin = rangeSlider.querySelector('.js-min');
            const inputMax = rangeSlider.querySelector('.js-max');
            const numberFormat = wNumb({ decimals: 0 });
            const presets  = rangeSlider.querySelectorAll('.js-preset');

            inputMin.initialValue = inputMin.value;
            inputMax.initialValue = inputMax.value;
            
            const handlePresets = (values) => {
                const minMatchesPreset = presets[0] !== undefined && parseInt(presets[0].dataset.min) === parseInt(values[0]);
                const maxMatchesPreset = presets[0] !== undefined && parseInt(presets[0].dataset.max) === parseInt(values[1]);
                const hasSinglePreset = presets.length === 1;

                if (hasSinglePreset && minMatchesPreset && maxMatchesPreset) {
                    presets[0].checked = true;
                } else {
                    presets.forEach(preset => {
                        preset.checked = false;
                    });
                }
            }

            handlePresets([parseInt(inputMin.value), parseInt(inputMax.value)]);

            const uiSlider = noUiSlider.create(rangeSliderUi, {
                start: [parseInt(inputMin.value) ?? 0, parseInt(inputMax.value) ?? 100],
                connect: true,
                step: 1,
                tooltips: [numberFormat, numberFormat],
                range: {
                    min: parseInt(rangeSliderUi.dataset.min || '000'),
                    max: parseInt(rangeSliderUi.dataset.max || '100'),
                },
            });

            if (presets.length) {
                for (const preset of presets) {
                    preset.addEventListener('change', e => {
                        const input = e.target;
                        if (input.checked) {
                            uiSlider.set([parseInt(input.dataset.min), parseInt(input.dataset.max)]);
                            inputMin.value = parseInt(input.dataset.min);
                            inputMax.value = parseInt(input.dataset.max);
                        } else {
                            uiSlider.set([parseInt(rangeSliderUi.dataset.min), parseInt(rangeSliderUi.dataset.max)]);
                            inputMin.value = parseInt(rangeSliderUi.dataset.min);
                            inputMax.value = parseInt(rangeSliderUi.dataset.max);
                        }
                    });
                }
            }

            inputMin.noUiSlider = uiSlider;
            inputMax.noUiSlider = uiSlider;

            uiSlider.on('change', values => {
                const formattedValues = values.map(value => numberFormat.to(value * 1));

                // eslint-disable-next-line prefer-destructuring
                inputMin.value = formattedValues[0];
                // eslint-disable-next-line prefer-destructuring
                inputMax.value = formattedValues[1];

                handlePresets(formattedValues);

                const changeEvent = new Event('change', { bubbles: true });
                rangeSlider.dispatchEvent(changeEvent);
            });
        });
    }


    /**
     * Applies Range Slider
     * https://refreshless.com/nouislider/
     */
    const rangeSingleSliders = [...parentElement.querySelectorAll('.js-range-single')];

    if (rangeSingleSliders.length > 0) {
        rangeSingleSliders.forEach(function applyRangeSlider(rangeSingleSlider) {
            const rangeSliderUi = rangeSingleSlider.querySelector('.js-rangeUi');
            const hiddenInput = rangeSingleSlider.querySelector('.js-hiddenInput');
            const valueDisplay = rangeSingleSlider.querySelector('.js-valueDisplay');

            const numberFormat = wNumb({ decimals: 0 });
            const uiSlider = noUiSlider.create(rangeSliderUi, {
                start: [parseInt(hiddenInput.value) ?? 0],
                connect: true,
                step: 1,
                tooltips: [numberFormat],
                range: {
                    min: parseInt(rangeSliderUi.dataset.min || '000'),
                    max: parseInt(rangeSliderUi.dataset.max || '100'),
                },
            });

            hiddenInput.noUiSlider = uiSlider;

            uiSlider.on('change', values => {
                const [value] = values

                hiddenInput.value = value * 1;
                valueDisplay.innerHTML = value * 1;

                const changeEvent = new Event('change', { bubbles: true });
                rangeSingleSlider.dispatchEvent(changeEvent);
            });
        });
    }

    /**
     * Markdown Editor (programmatically sets value on hidden input)
     * https://github.com/nhn/tui.editor/tree/master/apps/editor
     */
    const markdownEditors = [...parentElement.querySelectorAll('.js-markdown')];

    if (markdownEditors.length > 0) {
        markdownEditors.forEach(markdownEditor => {
            const editorElement = markdownEditor.querySelector('.js-mdEditor');
            const editorInput = markdownEditor.querySelector('.js-mdInput');

            const editor = new MdEditor({
                el: editorElement,
                height: '400px',
                initialValue: editorInput.value,
                initialEditType: 'wysiwyg',
                toolbarItems: ['bold', 'italic', 'strike', 'ul', 'ol', 'link'],
                events: {
                    change: () => {
                        editorInput.value = editor.getMarkdown();
                    },
                },
            });

            editor.setMarkdown(editorInput.value);
        });
    }

    /**
     * Show Password Toggle
     */
    const showPasswordToggles = [...parentElement.querySelectorAll('[data-password]')];

    if (showPasswordToggles.length > 0) {
        showPasswordToggles.forEach(showPasswordToggle => {
            const fieldValues = JSON.parse(showPasswordToggle.dataset.password);

            fieldValues.forEach(fieldValue => {
                // Escape any periods within an id selector
                const passwordInput = document.getElementById(fieldValue)
                showPasswordToggle.addEventListener('click', () => {
                    if (showPasswordToggle.checked) {
                        passwordInput.type = 'text';
                    } else {
                        passwordInput.type = 'password';
                    }
                });
            })
        });
    }

    /**
     * Maxlength Tracking
     */
    const maxLengthFields = [...parentElement.querySelectorAll('.js-maxLength')];

    if (maxLengthFields.length) {
        maxLengthFields.forEach(function applyMaxLengthTracker(maxLengthField) {
            const domparser = new DOMParser();
            const input = maxLengthField.querySelector('[maxlength]');
            const maxLength = input.maxLength * 1;
            const existingLength = input.value.length;
            const recalculate = e => {
                setTimeout(() => {
                    const newLength = input.value.length;
                    
                    note.innerHTML = `<p>${newLength}/${maxLength} Characters</p>`;

                    if (newLength > maxLength) {
                        note.classList.add('error');
                    } else {
                        note.classList.remove('error');
                    }
                }, 1)
            }

            const note = domparser
                .parseFromString(
                    `
                <div class="alert-naked info">
                    <p>${existingLength}/${maxLength} Characters</p>
                </div>
            `,
                    'text/html'
                )
                .querySelector('.alert-naked');

            maxLengthField.appendChild(note);

            input.addEventListener('input', recalculate);
        });
    }

    /**
     * Money Masking
     */
    const moneyOnlyFields = [...parentElement.querySelectorAll('.js-moneyMask')];

    if (moneyOnlyFields.length) {
        moneyOnlyFields.forEach(function applyMoneyOnlyMask(moneyOnlyField) {
            IMask(moneyOnlyField, {
                mask: Number,
                radix: '.',
            });
        });
    }

    /**
     * Remove unused params on
     * any GET.
     */
    const getForms = [...parentElement.querySelectorAll('form')].filter(form => {
        return form.method === 'get';
    });

    getForms.forEach(function applyEventListener(form) {
        form.addEventListener('submit', function cleanseParams(submit) {
            const formInputs = [...form.querySelectorAll('input')];

            formInputs.forEach(input => {
                const isNotValidValue = !input.value;

                if (isNotValidValue || input.dataset.disableSubmit) {
                    // eslint-disable-next-line no-param-reassign
                    input.disabled = true;
                }
            });
        });
    });

    /**
     * Remove double submissions on POST
     */
    const postForms = [...parentElement.querySelectorAll('form')].filter(form => {
        return form.method === 'post';
    });

    postForms.forEach(function applySubmitHandler(form) {
        form.addEventListener('submit', event => {
            const { submitter } = event;

            if (form.isSubmitted) {
                event.preventDefault();
                return false;
            }

            submitter.classList.add('-processing');
            form.isSubmitted = true;
            return true;
        });
    });

    /**
     * Masking for text inputs using pattern attribute
     */
    const patternInputs = [...parentElement.querySelectorAll('input[type="text"][pattern]')];

    patternInputs.forEach(function applyPatternMasking(patternInput) {
        const { pattern } = patternInput;
        const regex = new RegExp(pattern);

        const mask = IMask(patternInput, {
            mask: regex,
        });
    });
}

initInputs(document);
