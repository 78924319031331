/**
 * Tracks if an anchor has the class js-external and sets it to open in a new window
 */

function clickEvent(e) {
    e.stopPropagation();
    e.preventDefault();

    const win = window.open(e.target.href, '_blank');
    win.focus();
}

function openInNewTab(element) {
    if ([...element.classList].includes('js-external')) {
        element.setAttribute('target', '_blank');
    }
}

function isExternalURL(a) {
    if (a.href.length) {
        return !(location.hostname === a.hostname || !a.hostname.length);
    }
    return false;
}

window.addEventListener('content-populated', () => {
    const anchors = [...document.querySelectorAll('a')];

    anchors.forEach(anchor => {
        openInNewTab(anchor);
    });

    if (anchors.length) {
        const callback = function applyMutationHandling(mutationList) {
            mutationList.forEach(mutation => {
                const { attributeName, target } = mutation;

                if (attributeName === 'class') {
                    openInNewTab(target);
                }
            });
        };

        const config = {
            attributes: true,
        };

        const observer = new MutationObserver(callback);

        anchors.forEach(anchor => observer.observe(anchor, config));
    }
});

export default isExternalURL;
