const feeFields = [...document.querySelectorAll('[data-price]')];
const totalDueElement = document.querySelector('.js-totalDue');

// Create fallback for IE
const eventTrigger = window.document.documentMode ? 'change' : 'input';

function addUpTotal () {
    const checkedFeeFields = [...document.querySelectorAll('[data-price]:checked')];
    let total = 0
    
    if (checkedFeeFields.length) {
        const totals = checkedFeeFields.map(checkedFeeField => checkedFeeField.dataset.price * 1);

        total = totals.reduce((x, y) => x + y)
    }

    totalDueElement.innerHTML = `$${total.toFixed(2)}`;
};

if (feeFields.length) {
    feeFields.forEach(feeField => {
        feeField.addEventListener(eventTrigger, () => {
            addUpTotal();
        })
    });
}

addUpTotal();
