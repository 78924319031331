/**
 * For checkbox groups that have interdependent child parent relationships.
 * Automatically selects parent if any child is selected.
 */

const parentChildFieldsets = [...document.querySelectorAll('.js-parentChildOptions')];

const exposeChildren = parentId => {
    const children = [...document.querySelectorAll(`[data-child-label="${parentId}"]`)];

    children.forEach(child => child.classList.add('-active'));
};

const uncheckAndHideChildren = parentId => {
    const childrenLabels = [
        ...document.querySelectorAll(`[data-child-label="${parentId}"]`),
    ];
    const children = [...document.querySelectorAll(`[data-child="${parentId}"]`)];

    children.forEach(child => {
        child.checked = false;
    });
    childrenLabels.forEach(childLabel => childLabel.classList.remove('-active'));
};

if (parentChildFieldsets.length) {
    parentChildFieldsets.forEach(function applyParentChildSelection(parentChildFieldset) {
        const parents = [...parentChildFieldset.querySelectorAll('[data-parent]')];

        if (parents.length) {
            parents.forEach(parent => {
                const parentId = parent.dataset.parent;

                if (parent.checked) {
                    exposeChildren(parentId);
                }

                parent.addEventListener('input', () => {
                    if (parent.checked) {
                        exposeChildren(parentId);
                    } else {
                        uncheckAndHideChildren(parentId);
                    }
                });
            });
        }
    });
}
