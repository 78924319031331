/**
 * Logic for doing email validation for payment.js
 *
 * @param HTMLElement [form] Form DOM node
 */

import '../polyfills/append';
import '../polyfills/remove';

export default function(form) {
    const type  = form.querySelector('[name="cardType"]:checked');
    const email = form.querySelector('.js-emailMask');

    const typeContainer  = form.querySelector('.js-type');
    const emailContainer = form.querySelector('.js-email');

    const domparser = new DOMParser();

    /**
     * Type Validation
     */
    let typeErrorNode = null;

    if (typeContainer && !type) {
        typeErrorNode = domparser
            .parseFromString(
                `<p class="note -error">You must select a purchase type.</p>`,
                'text/html'
            )
            .querySelector('.note');

        typeContainer.append(typeErrorNode);
    }

    /**
     * Email Validation
     */
    let emailErrorNode = null;
    const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (emailContainer && !reg.test(String(email.value).toLowerCase())) {
        emailErrorNode = domparser
            .parseFromString(
                `<p class="note -error">The email needs to be in proper format.</p>`,
                'text/html'
            )
            .querySelector('.note');

        emailContainer.append(emailErrorNode);
    }

    if (
           typeErrorNode
        || emailErrorNode
    ) {
        return false;
    }

    return true;
}
