/**
 * Logic for doing basic payment form validation.
 * NOTE: specific societies may need their own payment handlers.
 * If this is the case, call this function in that site specific script instead.
 * Author: Imarc 2020
 *
 * @param HTMLElement [form] Form DOM node
 */

import '../polyfills/append';
import '../polyfills/remove';

export default function(form) {
    const ccFirstNameField  = form.querySelector('[name="billingCard.firstName"]');
    const ccLastNameField   = form.querySelector('[name="billingCard.lastName"]');
    const ccField           = form.querySelector('[name="billingCard.number"]');
    const cvvField          = form.querySelector('[name="billingCard.cvv"]');
    const expiryMonth       = form.querySelector('[name="billingCard.expiryMonth"]');
    const expiryYear        = form.querySelector('[name="billingCard.expiryYear"]');
    const ccPostalCodeField = form.querySelector('[name="billingCard.postalCode"]');

    const cardFirstNameContainer = form.querySelector('.js-cardFirstName');
    const cardLastNameContainer  = form.querySelector('.js-cardLastName');
    const cardNumbersContainer   = form.querySelector('.js-cardNumbers');
    const expirationContainer    = form.querySelector('.js-expiration');
    const postalCodeContainer    = form.querySelector('.js-postalCode');

    const domparser = new DOMParser();

    /**
     * Card First Name Validation
     */
    let ccFirstNameErrorNode = null;
    const ccFirstName = ccFirstNameField.value;

    if (ccFirstName.length <= 0) {
        ccFirstNameErrorNode = domparser
            .parseFromString(
                `
                <p class="note -error">
                    The first name can't be left blank.
                </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        cardFirstNameContainer.append(ccFirstNameErrorNode);
    }


    /**
     * Card Last Name Validation
     */
    let ccLastNameErrorNode = null;
    const ccLastName = ccLastNameField.value;

    if (ccLastName.length <= 0) {
        ccLastNameErrorNode = domparser
            .parseFromString(
                `
                <p class="note -error">
                    The last name can't be left blank.
                </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        cardLastNameContainer.append(ccLastNameErrorNode);
    }

    /**
     * CC Number Validate
     */
    const ccErrors  = [];
    const ccNumber  = ccField.value;
    const cvvNumber = cvvField.value;

    if (ccNumber.length > 16 || ccNumber.length < 15) {
        ccErrors.push(
            'The credit card number you entered is incorrect. Number should have at least 15 digits.'
        );
    }

    if (cvvNumber.length > 4 || cvvNumber.length < 3) {
        ccErrors.push(
            'The csc/cvv2 number you entered is incorrect. Number should have at least 3 digits.'
        );
    }

    if (ccErrors.length > 0) {
        ccErrors.forEach(error => {
            const ccErrorNode = domparser
                .parseFromString(
                    `
                        <p class="note -error">
                            ${error}
                        </p>
                    `,
                    'text/html'
                )
                .querySelector('.note');

            cardNumbersContainer.append(ccErrorNode);
        });
    }

    /**
     * Expiration Validate
     */
    let   expErrorNode  = null;
    const currentYear   = new Date().getFullYear();
    const currentMonth  = new Date().getMonth() + 1;
    const selectedYear  = expiryYear.value * 1;
    const selectedMonth = expiryMonth.value * 1;

    if (!selectedMonth) {
        expErrorNode = domparser
            .parseFromString(
                `
                    <p class="note -error">
                        The expiration month cannot be left blank.
                    </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        expirationContainer.append(expErrorNode);
    }


    if (!selectedYear) {
        expErrorNode = domparser
            .parseFromString(
                `
                    <p class="note -error">
                        The expiration year cannot be left blank.
                    </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        expirationContainer.append(expErrorNode);
    }


    if (selectedYear && selectedMonth && (selectedYear == currentYear && selectedMonth < currentMonth)) {
        expErrorNode = domparser
            .parseFromString(
                `
                    <p class="note -error">
                        The expiration date you selected has since passed. Please double check that your card has not expired.
                    </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        expirationContainer.append(expErrorNode);
    }

    /**
     * Postal Code Validation
     */
    let postalCodeErrorNode = null;
    const ccPostalCode = ccPostalCodeField.value;

    if (ccPostalCode.length < 1) {
        postalCodeErrorNode = domparser
            .parseFromString(
                `
                    <p class="note -error">
                        Please enter a valid postal code.
                    </p>
                `,
                'text/html'
            )
            .querySelector('.note');

        postalCodeContainer.append(postalCodeErrorNode);
    }

    if (
           ccFirstNameErrorNode
        || ccLastNameErrorNode
        || expErrorNode
        || postalCodeErrorNode
        || ccErrors.length > 0
    ) {
        return false;
    }

    return true;
}
