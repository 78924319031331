/**
 * Select All/Unselect All
 *
 */


// Control Parent Checkbox if all child boxes are selected
const controlParentCheckbox = function(parent, childObj ) {

    let checkboxesState = [...childObj].every(checkbox => {
        return checkbox.checked
    })

    if (checkboxesState) {
        return parent.checked = true
    } else {
        return parent.checked = false
    }

}

// Grab all children that have the data set labeled "data-parent-checkbox"
const childrenInputs = document.querySelectorAll('[data-parent-checkbox]');

const parentSelectors = [];

// Loop through children, get dataset, and only push unique values to parentSelectors array
childrenInputs.forEach(child => {

    let childDataset = child.dataset.parentCheckbox;

    if(!parentSelectors.includes(childDataset) ){
        parentSelectors.push(childDataset)
    }
    return; 
})



parentSelectors.forEach(parentSelector => {

    const parentCheckbox = document.querySelector(`${parentSelector}`);
    const childCheckBoxes = Array.from(childrenInputs).filter(childCheckBox => {
        return parentCheckbox.matches(childCheckBox.dataset.parentCheckbox);
    });
    
    // Check if all childboxes are selected onload
    controlParentCheckbox(parentCheckbox, childCheckBoxes)

    parentCheckbox.addEventListener('change', e => {

        if(parentCheckbox.checked){
            
            childCheckBoxes.forEach(checkbox => {
                checkbox.checked = true 
            })
            
        } else {

            childCheckBoxes.forEach(checkbox => {
                checkbox.checked = false 
            })
        }

    })

    // Control Parent Checkbox if all child boxes change
    childCheckBoxes.forEach(checkbox => checkbox.addEventListener('change', e => {

        controlParentCheckbox(parentCheckbox, childCheckBoxes)

    }))

})

