const showForFields = [...document.querySelectorAll('[data-requires]')];

showForFields.forEach(field => {
    const configObject = JSON.parse(field.dataset.requires);

    Object.keys(configObject).forEach(key => {
        //
        // Fields are reversable if they're !
        //
        let fieldName = key;
        let mode      = true;

        let value;
        let associatedFieldWrapper;

        if (key.indexOf('!') === 0) {
            fieldName = key.substring(1, key.length);
            mode      = false;
        }

        // Note this must be like this because of functional hidden fields
        const associatedControllingField = document.querySelector(`[name="${fieldName}"]:not([type="hidden"])`);
        if (associatedControllingField) {
            if (associatedControllingField.type && associatedControllingField.type.toLowerCase() == 'checkbox') {
                value = associatedControllingField.checked ? 1 : 0;
                
            } else if (typeof associatedControllingField !== 'undefined') {
                value = associatedControllingField.value;
            }
        }

        if (field.tagName.toLowerCase() == 'option') {
            associatedFieldWrapper = field;

        } else {
            associatedFieldWrapper = field.closest('.field');
        }

        if (
            typeof value !== 'undefined' &&
            ((configObject[key].includes(String(value)) && mode) || 
            (!configObject[key].includes(String(value)) && !mode))
        ) {
            associatedFieldWrapper.style.display = 'block';
            field.disabled = false;

        } else {
            associatedFieldWrapper.style.display = 'none';
            field.disabled = true;

            if (field.selected) {
                field.closest('select').selectedIndex = -1;
            }
        }

        if (associatedControllingField) {

            associatedControllingField.addEventListener('change', () => {
                if (associatedControllingField) {
                    if (associatedControllingField.type && associatedControllingField.type.toLowerCase() == 'checkbox') {
                        value = associatedControllingField.checked ? 1 : 0;
                        
                    } else if (typeof associatedControllingField !== 'undefined') {
                        value = associatedControllingField.value;
                    }
                }
                
                if (
                    (configObject[key].includes(String(value)) && mode) ||
                    (!configObject[key].includes(String(value)) && !mode)
                ) {
                    associatedFieldWrapper.style.display = 'block';
                    field.disabled = false;

                    if (typeof window.selectrInstances !== 'undefined' && typeof window.selectrInstances[fieldName] !== 'undefined') {
                        window.selectrInstances[fieldName].enable();
                    } 

                } else {
                    associatedFieldWrapper.style.display = 'none';
                    field.disabled = true;

                    if (field.selected) {
                        field.closest('select').selectedIndex = -1;
                    }
                }
            });
        }
    });
});
