const printTriggers = [...document.querySelectorAll('.js-print')];

if (printTriggers.length) {
    printTriggers.forEach(printTrigger => {
        printTrigger.addEventListener('click', e => {
            e.preventDefault();
            printTrigger.classList.add('-processing');

            const printId = printTrigger.dataset.print;
            const printContainer = document.querySelector(`[data-print="${printId}"]`);
            const printTitle = printContainer.dataset.printtitle;

            const WinPrint = window.open('', 'PRINT', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=1,status=0');
            const cssLinkTag = document.querySelector('link[rel="stylesheet"]').cloneNode(true);

            WinPrint.document.write(printContainer.innerHTML);
            WinPrint.document.head.append(cssLinkTag);
            WinPrint.document.close();
            WinPrint.document.body.style.backgroundColor = '#fff';
            WinPrint.document.body.style.overflowY = 'visible';
            WinPrint.document.body.style.height = 'auto';

            if (typeof printTitle !== 'undefined') {
                WinPrint.document.title = printTitle;
            }

            WinPrint.setTimeout(() => {
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
                printTrigger.classList.remove('-processing');
            }, 1000)
        });
    });
}